// Generated by Framer (400c93f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["H0eRBzowt", "Q7z3tXeIA"];

const serializationHash = "framer-syc00"

const variantClassNames = {H0eRBzowt: "framer-v-1t9z6tf", Q7z3tXeIA: "framer-v-12lqm1x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const transition2 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const animation1 = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition2, x: 0, y: 0}

const transition3 = {delay: 0, duration: 1, ease: [0, 0, 1, 1], type: "tween"}

const animation2 = {opacity: 1, rotate: 360, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Hidden: "Q7z3tXeIA", Loading: "H0eRBzowt"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "H0eRBzowt"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "H0eRBzowt", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const isDisplayed = () => {
if (baseVariant === "Q7z3tXeIA") return false
return true
}

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}>{isDisplayed() && (<Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1t9z6tf", className, classNames)} data-framer-name={"Loading"} layoutDependency={layoutDependency} layoutId={"H0eRBzowt"} ref={refBinding} style={{...style}}><MotionDivWithFX __framer__animate={{transition: transition2}} __framer__animateOnce={false} __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={"framer-1e5ro5y"} data-framer-name={"Spinner"} layoutDependency={layoutDependency} layoutId={"WmjGbtXBu"} style={{mask: "url('https://framerusercontent.com/images/pGiXYozQ3mE4cilNOItfe2L2fUA.svg') alpha no-repeat center / cover add", WebkitMask: "url('https://framerusercontent.com/images/pGiXYozQ3mE4cilNOItfe2L2fUA.svg') alpha no-repeat center / cover add"}}><MotionDivWithFX __framer__loop={animation2} __framer__loopEffectEnabled __framer__loopRepeatDelay={0} __framer__loopRepeatType={"loop"} __framer__loopTransition={transition3} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={"framer-2hzn3c"} data-framer-name={"Conic"} layoutDependency={layoutDependency} layoutId={"DienkbJM4"} style={{background: "conic-gradient(from 0deg at 50% 50%, rgba(255, 255, 255, 0) 0deg, rgb(153, 153, 153) 342deg)"}}><motion.div className={"framer-1u0d12p"} data-framer-name={"Round"} layoutDependency={layoutDependency} layoutId={"ALhlSttZf"} style={{backgroundColor: "rgb(153, 153, 153)", borderBottomLeftRadius: 1, borderBottomRightRadius: 1, borderTopLeftRadius: 1, borderTopRightRadius: 1}}/></MotionDivWithFX></MotionDivWithFX></motion.div></Transition>)}</Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-syc00.framer-2yvfos, .framer-syc00 .framer-2yvfos { display: block; }", ".framer-syc00.framer-1t9z6tf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; padding: 0px; position: relative; width: 40px; }", ".framer-syc00 .framer-1e5ro5y { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 20px); overflow: visible; position: relative; width: 20px; }", ".framer-syc00 .framer-2hzn3c { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-syc00 .framer-1u0d12p { flex: none; height: 2px; left: calc(50.00000000000002% - 2px / 2); overflow: visible; position: absolute; top: 0px; width: 2px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-syc00.framer-1t9z6tf { gap: 0px; } .framer-syc00.framer-1t9z6tf > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-syc00.framer-1t9z6tf > :first-child { margin-left: 0px; } .framer-syc00.framer-1t9z6tf > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Q7z3tXeIA":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerD1hImSPzQ: React.ComponentType<Props> = withCSS(Component, css, "framer-syc00") as typeof Component;
export default FramerD1hImSPzQ;

FramerD1hImSPzQ.displayName = "Spinner";

FramerD1hImSPzQ.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerD1hImSPzQ, {variant: {options: ["H0eRBzowt", "Q7z3tXeIA"], optionTitles: ["Loading", "Hidden"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerD1hImSPzQ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})